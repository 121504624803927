import React from 'react'
import Navbar from '../components/Navbar.js';
//import Footer from '../components/Footer.js';
import HeroImg2 from '../components/HeroImg2.js';

const Contact = () => {
  return <div>
    <Navbar/>
    <HeroImg2 heading = "CONTACT US." text="For information about enrollment or any questions you have, contact us at 425-240-8724 or kickforcausesportscamp@gmail.com"/>
    {/*<Footer/>*/}
  </div>
};

export default Contact;